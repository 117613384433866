import React, { useState, useEffect } from "react";
import axios from "axios";

const LeaveStatus = () => {
  const [singleUser, setSingleUser] = useState("");
  const [user, setUser] = useState("");
  const [leaveDetails, setLeaveDetails] = useState(null);
  const userString = localStorage.getItem("user");
  
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const maxLeaveSickDays = 7;
  const maxLeaveCasualDays = 9;
  const SickDue = maxLeaveSickDays - (singleUser?.SickLeaveLeft || 0);
  const CasualDue = maxLeaveCasualDays - (singleUser?.CasualLeaveLeft || 0);

  // Parse user info from localStorage
  useEffect(() => {
    if (userString) {
      const userObject = JSON.parse(userString);
      console.log("userObject:", userObject);
      setUser(userObject);
    }
  }, [userString]);

  // Fetch user and leave information
  const getLeaveInfo = async () => {
    try {
      if (user) {
        // Fetch employee info
        const response = await axios.get(
          `${apiUrl}/employee/${user.employeeID}`
        );
        console.log("Employee Info:", response.data.employeeInfo);
        setSingleUser(response.data.employeeInfo);

        // Fetch leave details
        const leaveResponse = await axios.get(
          `${apiUrl}/leave/detailsEmpID/${user.employeeID}`
        );
        console.log("Leave Details:", leaveResponse.data);
        setLeaveDetails(leaveResponse.data.data);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  // Trigger fetching data when user info is available
  useEffect(() => {
    if (user) {
      getLeaveInfo();
    }
  }, [user ? user.employeeID : ""]);

  return (
    <div className="flex-1 p-4">
      <h2 className="text-2xl font-semibold">Leave Status</h2>

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="min-w-full py-2 sm:px-6 lg:px-8 flex flex-col">
            <div>
              <p className="text-center font-semibold text-lg">Summary</p>

              <table className="min-w-full text-left text-sm font-light">
  <thead className="border-b dark:border-neutral-500 text-center">
    <tr>
      <th>Total Leave</th>
      <th>Leave Taken</th>
      <th>Leave Due</th>
    </tr>
  </thead>
  <tbody>
    <tr className="font-bold text-center border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
      {/* Total Leave */}
      <td className="p-2 bg-blue-200 hover:bg-blue-400">
        {leaveDetails?.remainingLeaves?.TotalLeave ?? "N/A"}
      </td>
      
      {/* Leave Taken: TotalLeave - TotalDue */}
      <td className="p-2 bg-red-200 hover:bg-red-400">
        {leaveDetails?.remainingLeaves
          ? leaveDetails.remainingLeaves.TotalLeave - leaveDetails.remainingLeaves.TotalDue
          : "N/A"}
      </td>
      
      {/* Leave Due: Remaining leaves or fallback to "N/A" */}
      <td className="p-2 bg-green-200 hover:bg-emerald-500">
        {leaveDetails?.remainingLeaves?.TotalDue ?? "N/A"}
      </td>
    </tr>
  </tbody>
</table>

            </div>
            <div className="divider m-0" />
            <div>
              <p className="text-center font-semibold text-lg">Casual Leave</p>
              <table className="min-w-full text-left text-sm font-light">
                <thead className="border-b dark:border-neutral-500 text-center">
                  <tr>
                    <th>Total</th>
                    <th>Taken</th>
                    <th>Due</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="font-bold text-center border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                    <td className="p-2 bg-blue-200 hover:bg-blue-400">
                    {leaveDetails?.remainingLeaves?.initialCasualLeave ?? "N/A"}
                    </td>
                    <td className="p-2 bg-red-200 hover:bg-red-400">
                    {leaveDetails?.remainingLeaves?.takenCasualLeave ?? "N/A"}
                    </td>
                    {/* Casual Leave Due: initialCasualLeave - takenCasualLeave */}
                    <td className="p-2 bg-green-200 hover:bg-emerald-500">
                      {leaveDetails?.remainingLeaves
                        ? leaveDetails.remainingLeaves.initialCasualLeave -
                          leaveDetails.remainingLeaves.takenCasualLeave
                        : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="divider m-0" />
            <div>
              <p className="text-center font-semibold text-lg">Sick Leave</p>
              <table className="min-w-full text-left text-sm font-light">
                <thead className="border-b dark:border-neutral-500 text-center">
                  <tr>
                    <th>Total</th>
                    <th>Taken</th>
                    <th>Due</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="font-bold text-center border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                    <td className="p-2 bg-blue-200 hover:bg-blue-400">
                    {leaveDetails?.remainingLeaves?.initialSickLeave ?? "N/A"}
                    </td>
                    <td className="p-2 bg-red-200 hover:bg-red-400">
                    {leaveDetails?.remainingLeaves?.takenSickLeave ?? "N/A"}
                    </td>
                    <td className="p-2 bg-green-200 hover:bg-emerald-500">
                      {/* {due > 0 ? due : "No due left"} */}
                      {leaveDetails?.remainingLeaves
                      ? leaveDetails.remainingLeaves.initialSickLeave -
                        leaveDetails.remainingLeaves.takenSickLeave
                      : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveStatus;
