import axios from "axios";
import EditIcon from "@mui/icons-material/BorderColor";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { hoverScale } from "../../../components/DesignStandardize";
import AdminEditLeaveApplication from "./AdminEditLeaveApplication";

const Pending = ({ id }) => {
  const [approve, setApprove] = useState(false);
  const [disapprove, setDisapprove] = useState(false);
  const [leaveInfo, setLeaveInfo] = useState("");
  const [leaveDetails, setLeaveDetails] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [singleLeaveInfo, setSingleLeaveInfo] = useState("");
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  let myEmployeeId = null;

  const toggleEditModal = () => {
    setShowEditModal((prevShowEditModal) => !prevShowEditModal);
  };

  // Function to get leave information by Employee ID
  const getLeaveDetailsByEmployeeId = async (employeeId) => {
    try {
      const response = await axios.get(`${apiUrl}/leave/detailsEmpID/${employeeId}`);
      setLeaveDetails(response.data.data); // Update state with fetched data
      console.log("Leave details fetched from API:", response.data.data); // Log the fetched data directly
    } catch (error) {
      console.error("Error fetching leave details by Employee ID:", error);
    }
  };

  // Function to handle leave approval
  const handleApproveChange = async () => {
    setApprove(!approve);
    try {
      await updateLeaveForEmployees();
      const data = {
        Status: 1,
      };

      await axios.put(`${apiUrl}/leave/update/${id}`, data);
      toast.success("Leave has been Approved");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      toast.error("An error has occurred!");
      console.error(error);
    }
    if (!approve) {
      setDisapprove(false);
    }
  };

  const updateLeaveForEmployees = async () => {
    try {
      const data = {
        totalDates: leaveInfo.Total,
      };
      if (leaveInfo.Type === "Sick") {
        await axios.put(
          `${apiUrl}/employee/ELeaveUpdate/${leaveInfo.EmployeeID}`,
          data
        );
      } else {
        await axios.put(
          `${apiUrl}/employee/CLeaveUpdate/${leaveInfo.EmployeeID}`,
          data
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getIndividualLeaveInfo = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/employee/${leaveInfo.EmployeeID}`
      );
      setSingleLeaveInfo(response.data.employeeInfo);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (leaveInfo.EmployeeID) {
      myEmployeeId = leaveInfo.EmployeeID;
      console.log("Updated myEmployeeId:", myEmployeeId);

      // Fetch leave details by Employee ID
      getLeaveDetailsByEmployeeId(myEmployeeId);

      // Fetch additional individual leave info
      getIndividualLeaveInfo();
    }
  }, [leaveInfo.EmployeeID]);

  const getLeaveInformation = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/leave/detailsByLeaveID/${id}`
      );
      setLeaveInfo(response.data.leaves);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLeaveInformation();
  }, [id]);

  const handleDisapproveChange = async () => {
    setDisapprove(!disapprove);
    try {
      const data = {
        Status: 2,
      };
      await axios.put(`${apiUrl}/leave/update/${id}`, data);
      toast.success("Leave Application Denied!!");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.error(error);
    }
    if (!disapprove) {
      setApprove(false);
    }
  };



  return (
    <>
      <div className="flex mt-20 flex-col flex-wrap lg:flex-row gap-10 md:divide-x-2 md:divide-blue-200 border-2 border-solid">
        {/*FIRST-SECTION APPLICATION*/}
        <div className="flex-1 p-4">
          {/* Content for the first section */}
          <h2 className="text-2xl font-semibold mb-5">Application for leave</h2>

          {/*Name*/}

          <div className="flex flex-col w-full gap-5 mb-3">
            <p className="text-left text-xl mb-3">Name: {leaveInfo.Name}</p>
          </div>

          {/*LEAVE APPLY DATE*/}

          <div className="flex flex-col w-full gap-5 mb-3">
            <p className="text-left text-xl mb-3">
              Applied date: {leaveInfo.DateApplied}
            </p>
          </div>

          {/* DATES APPLIED FOR LEAVE */}
          <p className="text-left text-xl mb-3">
            Leave Dates Applied:
            {leaveInfo.Date ? (
              <ul className=" font-semibold text-2xl">
                {JSON.parse(leaveInfo.Date).map((date, index) => (
                  <li key={index}>{format(new Date(date), "yyyy-MM-dd")}</li>
                ))}
              </ul>
            ) : (
              <span>No leave dates available.</span>
            )}
          </p>

          {/*CAUSE*/}

          <div className="flex flex-col w-full gap-5 mb-3">
            <p className="text-left text-xl">Cause: {leaveInfo.Cause}</p>
          </div>

          {/*LEAVE TYPE*/}

          <div className="flex flex-col w-full gap-5 mb-3">
            <p className="text-left text-xl mb-3">
              Leave Type: {leaveInfo.Type}
            </p>
          </div>

          {/*ADDRESS*/}

          <div className="flex flex-col w-full gap-5 mb-3">
            <p className="text-left text-xl mb-3">
              Address during the leave period: {leaveInfo.Address}
            </p>
          </div>

          {/*CONTACT*/}

          <div className="flex flex-col w-full gap-5 mb-5">
            <p className="text-left text-xl mb-3">
              Contact Number: {leaveInfo.Contact}
            </p>
          </div>
        </div>

        {/* SECOND-SECTION LEAVE STATUS */}
        <div className="flex-1 p-4">
          {/* Content for the second section */}
          <h2 className="text-2xl font-semibold">Leave Status</h2>

          <table className="min-w-full text-left text-sm font-light">
            <thead className="border-b font-medium dark:border-neutral-500 text-center text-xl">
              <tr>
                <th scope="col" className="px-6 py-4">
                  Total
                </th>
                <th scope="col" className="px-6 py-4">
                  Leave
                </th>
                <th scope="col" className="px-6 py-4">
                  Due
                </th>
              </tr>
            </thead>
            <tbody>
            <tr className="font-bold text-center border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
            <td className="whitespace-nowrap px-6 py-4 bg-blue-200 hover:bg-blue-400">
              {leaveDetails?.remainingLeaves?.TotalLeave || 0} {/* Display Total Leaves */}
            </td>
            <td className="whitespace-nowrap px-6 py-4 bg-red-200 hover:bg-red-400">
              {leaveDetails?.remainingLeaves?.TotalLeave - leaveDetails?.remainingLeaves?.TotalDue || 0} {/* Display Leave (Total - Due) */}
            </td>
            <td className="whitespace-nowrap px-6 py-4 bg-green-200 hover:bg-emerald-500">
              {leaveDetails?.remainingLeaves?.TotalDue || 0} {/* Display Total Due */}
            </td>
          </tr>
            </tbody>
          </table>

          <p className="text-left text-2xl text-blue-900">
            Current Needed leave: {leaveInfo.Total}
          </p>
        </div>

        {/* THIRD SECTION GIVE APPROVAL */}
        <div className="flex-1 p-4">
          <h2 className="text-2xl font-semibold mb-5">Approval</h2>

          <p className="text-center text-2xl mb-3">
            Do you approve this application?
          </p>

          <div class="flex items-center mb-4 text-xl">
            <input
              id="approve-checkbox"
              type="checkbox"
              value=""
              checked={approve}
              onChange={handleApproveChange}
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              for="default-checkbox"
              class="ms-2 font-medium text-gray-900 dark:text-gray-300"
            >
              Approve
            </label>
          </div>

          <div class="flex items-center mb-4 text-xl">
            <input
              id="disapprove-checkbox"
              type="checkbox"
              onChange={handleDisapproveChange}
              checked={disapprove}
              value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              for="default-checkbox"
              class="ms-2 font-medium text-gray-900 dark:text-gray-300"
            >
              Disapprove
            </label>
          </div>

          <button
            className={`text-blue-500 hover:cursor-pointer ${hoverScale}`}
            onClick={toggleEditModal}
          >
            <EditIcon />{" "}
            <span className="text-xl font-medium text-gray-900 dark:text-gray-300">
              Change the dates
            </span>
          </button>
        </div>
      </div>
      {showEditModal && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleEditModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-3">
            <div className="card-title flex justify-between">
              <h2 className="text-center text-transparent bg-clip-text bg-gradient-to-b from-[#6782e6] to-[#214DED] md:font-extrabold m-0">
                Edit Dates Applied for Leave
              </h2>
              <CloseIcon
                onClick={toggleEditModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-[#414141] w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
            <AdminEditLeaveApplication id={id} dates={leaveInfo.Date} />
          </div>
        </div>
      )}
    </>
  );
};

export default Pending;
